<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="mb-1">
                    <img
                      src="https://ik.imagekit.io/w13m3d3rhif/web/icons/go-zayaan-logo-brand.svg"
                      alt="gozayaan-logo"
                    >
                  </div>
                  <b-card-text class="mb-25">
                    Sheltech Ayaan, House 58, Road 6 & 11
                  </b-card-text>
                  <b-card-text class="mb-25">
                    Block C, Level 2, Banani, Dhaka.
                  </b-card-text>
                  <b-card-text class="mb-0">
                    Phone: +880 9678332211
                  </b-card-text>
                  <b-card-text class="mb-0">
                    Email: info@gozayaan.com
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      :value="new Date()"
                      disabled
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-padding">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-0">
                    Invoice To:
                  </h6>

                  <!-- Select Client -->
                  <div
                    v-b-toggle.add-new-user
                    class="add-new-client-header d-flex align-items-center my-25"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    <span
                      class="align-middle ml-25"
                    >Add New Customer</span>
                  </div>

                  <!-- Selected Client -->
                  <div
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.customer_first_name }} {{ invoiceData.customer_last_name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer_email }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer_phone }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
              </b-row>
            </b-card-body>

            <!-- Product Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
              >
                <validation-observer ref="productForm">
                  <b-row
                    v-for="(product, p) in invoiceData.sale_items"
                    ref="row"
                    :key="p"
                    class="pb-2"
                  >
                    <b-col cols="12">

                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Detail
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Price
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Discount
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Total
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="d-flex border rounded">
                        <b-row
                          class="flex-grow-1 p-2"
                        >
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="product"
                              rules="required"
                              :vid="`product-${p}`"
                            >
                              <v-select
                                v-model="product.product_type"
                                :options="itemsOptions"
                                :reduce="option => option.value"
                                label="itemTitle"
                                :clearable="false"
                                class="item-selector-title"
                                placeholder="Select Item"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="price"
                              rules="required"
                              :vid="`price-${p}`"
                            >
                              <b-form-input
                                v-model="product.price"
                                type="number"
                                class="mb-2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="discount"
                              rules="required"
                              :vid="`discount-${p}`"
                            >
                              <b-form-input
                                v-model="product.discount"
                                type="number"
                                class="mb-2"
                                value="1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Total Amount</label>
                            <p class="mb-1">
                              {{ currency }}
                              {{ (Number(product.price) - Number(product.discount)).toLocaleString() }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <b-form-input
                              v-model="product.reference"
                              type="text"
                              class="mb-2"
                              placeholder="Reference"
                            />
                            <validation-provider
                              #default="{ errors }"
                              name="details"
                              rules="required"
                              :vid="`description-${p}`"
                            >
                              <b-form-textarea
                                v-model="product.description"
                                placeholder="Product Details"
                                class="mb-2 mb-lg-0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <div class="d-flex border pt-1">
                          <feather-icon
                            icon="XIcon"
                            size="18"
                            class="cursor-pointer"
                            @click="removeItem(p)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </validation-observer>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="addNewItem"
                >
                  + Add Item
                </b-button>
              </div>
            </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding">
              <b-row>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end align-self-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency }} {{ Number(totalAmount).toLocaleString() }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total Discount:
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency }} {{ Number(discountAmount).toLocaleString() }}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ currency }} {{ Number(totalPayableAmount).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="6"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="isSaveBusy"
            @click="validateForm()"
          >
            <b-spinner
              v-if="isSaveBusy"
              small
            />
            Save Invoice
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <customer-info @add-user="addUser" />
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  VBToggle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import helper from '@/mixins/helper'
import api from '@/libs/api'
import CustomerInfo from '@/components/sales-store/CustomerInfo.vue'

export default {
  name: 'InvoiceForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomerInfo,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [helper],
  data() {
    return {
      invoiceData: {
        customer_first_name: '',
        customer_last_name: '',
        customer_email: '',
        customer_phone: '',
        sale_items: [
          {
            product_type: '',
            description: '',
            reference: '',
            price: 0,
            discount: 0,
          },
        ],
      },
      currency: 'BDT',
      isSaveBusy: false,
      required,
      itemsOptions: [
        {
          itemTitle: 'Flight',
          description: 'Flight Booking.',
          value: 'FLIGHT',
        },
        {
          itemTitle: 'Hotel',
          description: 'Hotel Booking',
          value: 'HOTEL',
        },
        {
          itemTitle: 'Tour',
          description: 'Tour Booking',
          value: 'TOUR',
        },
        {
          itemTitle: 'Service Charge',
          description: 'Service Charge Fess',
          value: 'SERVICE_CHARGE',
        },
        {
          itemTitle: 'Convenience Fee',
          description: 'Convenience Fee',
          value: 'CONVENIENCE_CHARGE',
        },
      ],
    }
  },
  computed: {
    customerInfoValid() {
      return this.invoiceData.customer_first_name
        && this.invoiceData.customer_last_name
        && this.invoiceData.customer_phone
    },
    totalAmount() {
      return this.invoiceData.sale_items.reduce((accum, b) => accum + Number(b.price), 0)
    },
    discountAmount() {
      return this.invoiceData.sale_items.reduce((accum, b) => accum + Number(b.discount), 0)
    },
    totalPayableAmount() {
      return Number(this.totalAmount) - Number(this.discountAmount)
    },
  },
  methods: {
    addUser(data) {
      this.invoiceData.customer_first_name = data.first_name
      this.invoiceData.customer_last_name = data.last_name
      this.invoiceData.customer_email = data.email
      this.invoiceData.customer_phone = `${data.phone}`
    },
    addNewItem() {
      this.invoiceData.sale_items.push(
        {
          product_type: '',
          description: '',
          reference: '',
          price: 0,
          discount: 0,
        },
      )
    },
    removeItem(index) {
      this.invoiceData.sale_items.splice(index, 1)
    },
    validateForm() {
      this.$refs.productForm.validate().then(success => {
        if (success && this.customerInfoValid) {
          this.createInvoice()
        } else {
          this.notificationMessage('warning', 'AlertCircleIcon', 'Warning',
            'Fill up all required fields')
        }
      })
    },
    createInvoice() {
      this.isSaveBusy = true
      api.postData('b2e_dashboard/stores/invoices/', true, {
        ...this.invoiceData,
      }).then(response => {
        if (response.data.status) {
          this.$router.push(`/sales/invoice/preview/${response.data.result.id}`)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isSaveBusy = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>

import { render, staticRenderFns } from "./InvoiceForm.vue?vue&type=template&id=48344448&scoped=true&"
import script from "./InvoiceForm.vue?vue&type=script&lang=js&"
export * from "./InvoiceForm.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceForm.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./InvoiceForm.vue?vue&type=style&index=1&id=48344448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48344448",
  null
  
)

export default component.exports